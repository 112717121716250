import React from 'react'
import Button from 'components/Button'
import Table from 'components/Table'
import { useHistory } from 'react-router'

function List({
  headers,
  data,
  title,
  buttons,
  selectedData = [],
  onSelect,
  filter,
  loading,
  total,
  onIncrease,
  onDecrease,
}) {
  const router = useHistory()
  return (
    <Table
      title={title}
      headers={headers.map((e) => (
        <th scope="col" key={`thead-${Math.random()}`}>
          {e}
        </th>
      ))}
      loading={loading}
      filter={filter}
      buttons={buttons}
      total={total}
      rows={data?.map((e, index) => (
        <tr key={index}>
          <td>{index + 1}</td>
          <td>{e.code}</td>
          <td>{e.name}</td>
          <td>{e.wallet}</td>
          <td>
            <Button size="sm" color="success" onClick={() => onIncrease(e)}>
              Increase
            </Button>
            <Button size="sm" color="warning" onClick={() => onDecrease(e)}>
              Decrease
            </Button>
            <Button
              size="sm"
              color="info"
              onClick={() => router.push(`/history-query/${e.id}`)}
            >
              History
            </Button>
          </td>
        </tr>
      ))}
    />
  )
}

export default List
