import React, { useEffect, useState } from 'react'
import Button from 'components/Button'
import List from './list'
import FilterBox from './filter'
import { Modal, ModalBody, ModalFooter } from 'reactstrap'
import queryString from 'query-string'
import { useHistory } from 'react-router'
import usePaymentReport from 'hooks/usePaymentReport'
import AdsFinishDetail from './detail'

function PaymentLog() {
  const router = useHistory()
  const [showDetailModal, setShowDetailModal] = useState(false)
  const headers = [
    'No',
    'Code',
    'Member',
    'Plan',
    'Own Payment',
    'Refer Payment',
    'Total',

    'Actions',
  ]

  const query = queryString.parse(router.location.search)

  const { state, fetchPaymentReports } = usePaymentReport()

  useEffect(() => {
    if (query && query.date) {
      fetchPaymentReports(query)
    }
  }, [router.location])

  return (
    <>
      <List
        headers={headers}
        data={state.paymentReports}
        title="Payment Report"
        loading={state.loading}
        filter={<FilterBox />}
        onSelect={(e) => {}}
        buttons={<></>}
        viewDetail={async (code, date) => {
          // await fetchAdsFinishDetailLogs(code, date)
          // setShowDetailModal(true)
        }}
      />

      <Modal
        isOpen={showDetailModal}
        size="lg"
        style={{
          maxWidth: '1400px',
          width: '80%',
          minWidth: 400,
        }}
        backdrop={true}
      >
        <ModalBody>
          <AdsFinishDetail data={state.childLogs} />
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => setShowDetailModal(false)}>Close</Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default PaymentLog
