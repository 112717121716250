import useMember from 'hooks/useMember'
import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router'
import { Card, CardBody } from 'reactstrap'

import DirectMembers from './directMembers'
import MemberTreeView from './treeView'

function MemberDetail() {
  const { id } = useParams()

  const { fetchEachMember, state: memberState } = useMember()
  const [mounted, setMounted] = useState(false)

  const tree = useMemo(() => {
    if (memberState.member) {
      const result = JSON.parse(memberState.member.tree.tree)

      if (result.code === memberState.member.code) return result
      else {
        const codes = []
        for (var i = 1; i < memberState.member.code.length; i += 2) {
          codes.push(memberState.member.code.substr(0, i + 1))
        }

        let tempNode = null
        for (var node = 1; node < codes.length; node++) {
          let currentNode = node
          if (tempNode)
            tempNode = tempNode.children.find(
              (r) => r.code === codes[currentNode]
            )
          else
            tempNode = result.children.find(
              (r) => r.code === codes[currentNode]
            )
        }

        return tempNode
      }
    }
  }, [memberState.member])

  useEffect(() => {
    if (!mounted) {
      setMounted(true)
      fetchEachMember(id)
    }
  }, [id, mounted, fetchEachMember])

  if (memberState.loading) return <h2>Loading...</h2>

  if (memberState.member)
    return (
      <>
        <Card>
          <CardBody className="d-flex justify-content-between">
            <div>
              <h4>Name : {memberState.member.name}</h4>
              <h4>Code : {memberState.member.code}</h4>
            </div>
            <div>
              <h4>Added by : {memberState.member.parentCode || 'Admin'}</h4>
              <h4>Wallet : {memberState.member.wallet} USDT</h4>
            </div>
          </CardBody>
        </Card>
        <DirectMembers data={memberState.member.directedMembers} />
        <MemberTreeView data={tree} />
      </>
    )

  return <></>
}

export default MemberDetail
