import React from 'react'
import EntryGrid from 'components/Inputs/EntryGrid'
import { Col, FormGroup, Row, Form } from 'reactstrap'
import Button from 'components/Button'
import TextBox from 'components/Inputs/TextBox'
import { useForm } from 'react-hook-form'
import useTransferAllowance from 'hooks/useTransferAllowance'

function InputForm({ title, data }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm()

  React.useEffect(() => {
    if (data) {
      setValue('amount', data.amount)
    }
  }, [data, setValue])

  const {
    storeTransferAllowance,
    updateTransferAllowance,
  } = useTransferAllowance()

  const submitData = async (values) => {
    data
      ? await updateTransferAllowance(data.id, values)
      : await storeTransferAllowance(values)
    reset()
  }

  return (
    <EntryGrid title={title}>
      <Form onSubmit={handleSubmit(submitData)}>
        <div className="pt-2 pb-2">
          <Row>
            <Col md="4">
              <FormGroup>
                <label>Amount</label>
                <TextBox
                  className="form-control"
                  placeholder="Amount"
                  type="number"
                  registerProps={register('amount', {
                    required: 'Amount is required',
                  })}
                  errors={errors.amount}
                />
              </FormGroup>
            </Col>
          </Row>
          <div className="mt-5 text-center">
            <Button>Save</Button>
            <Button
              className="ml-2"
              onClick={() => {
                reset()
              }}
            >
              Reset
            </Button>
          </div>
        </div>
      </Form>
    </EntryGrid>
  )
}

export default InputForm
