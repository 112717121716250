import React from 'react'
import {
  getAll,
  each,
  store,
  deleteData,
  update,
  softDelete,
} from 'services/planService'

const initialState = { plans: [], loading: false }

function reducer(state, action) {
  switch (action.type) {
    case 'SET_PLAN':
      return { ...state, plans: action.payload.data }
    case 'SET_LOADING':
      return { ...state, loading: action.payload }
    default:
      throw new Error()
  }
}

const usePlan = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  const fetchPlan = async () => {
    dispatch({ type: 'SET_LOADING', payload: true })
    let data = await getAll()
    dispatch({ type: 'SET_PLAN', payload: { data: data } })
    dispatch({ type: 'SET_LOADING', payload: false })
  }
  const getPlan = async (id) => {
    dispatch({ type: 'SET_LOADING', payload: true })
    let data = await each(Number(id))

    dispatch({ type: 'SET_PLAN', payload: { data: data } })
    dispatch({ type: 'SET_LOADING', payload: false })
  }
  // const getPlan = async (id) => {
  //    dispatch({ type: 'SET_LOADING', payload: true })
  //    let data = dummyData.filter((d) => {
  //      return d.id == id
  //    })

  //    dispatch({ type: 'SET_PLAN', payload: { data: data } })
  //    dispatch({ type: 'SET_LOADING', payload: false })
  // }

  const storePlan = async (values) => {
    await store(values)
  }
  const updatePlan = async (id, values) => {
    await update(id, values)
  }
  const softDeletePlan = async (id) => {
    await softDelete(id)
  }
  const deletePlan = async (id) => {
    await deleteData(id)
  }


  return {
    getPlan,
    fetchPlan,
    storePlan,
    updatePlan,
    softDeletePlan,
    deletePlan,
    state: state,
  }
}

export default usePlan
