import React from 'react'
import Button from 'components/Button'
import List from './list'
import FilterBox from './filter'
import { useState } from 'react'
import useDeposit from 'hooks/useDeposit'
import queryString from 'query-string'
import ConfirmDialog from 'components/Dialogs/ConfirmDialog'
import DetailDialog from '../detail'
import { useHistory } from 'react-router'

function Deposit() {
  const router = useHistory()
  const [modalOpen, setModalOpen] = useState(false)
  const [editData, setEditData] = React.useState(false)
  const [showConfirm, setShowConfirm] = React.useState(false)
  const [modalDelete, setModalDelete] = useState(false)

  const query = queryString.parse(router.location.search)
  let url = ''
  if (query.page) url += `?page=${query.page}`
  if (query.limit) url += `&limit=${query.limit}`
  if (query.start_date && query.end_date)
    url += `&start_date=${query.start_date}&end_date=${query.end_date}`
  if (query.search) url += `&search=${query.search}`

  const headers = [
    'No',
    'Transcation ID',
    'Name',
    'Code',
    'Amount',
    'Note',
    'Image',
    'Status',
    'Created At',
    '',
  ]

  const { state, fetchDeposit, confirmDeposit, deleteDeposit } = useDeposit()

  React.useEffect(() => {
    fetchDeposit(url)
  }, [url])

  return (
    <>
      <List
        headers={headers}
        total={state.total}
        data={state.deposits}
        totalAmount={state.totalAmount}
        title="Deposit"
        onConfirm={(data) => {
          setEditData(data)
          setShowConfirm(true)
        }}
        onEdit={(data) => {
          setEditData(data)
          setModalOpen(true)
        }}
        onDelete={(data) => {
          setEditData(data)
          setModalDelete(true)
        }}
        loading={state.loading}
        filter={<FilterBox />}
        onSelect={(e) => {}}
        buttons={<></>}
      />
      <DetailDialog
        modalOpen={modalOpen}
        data={editData}
        onToggle={() => {
          setModalOpen(false)
          fetchDeposit(url)
        }}
      />
      <ConfirmDialog
        modalOpen={showConfirm}
        title={`Confirm Deposit`}
        body={`Are you sure to confirm Transcation ID(${editData?.id})?`}
        onToggle={() => setShowConfirm(false)}
        onConfirm={() => {
          setShowConfirm(false)
          confirmDeposit(editData.id)
          setTimeout(() => {
            fetchDeposit(url)
          }, 1000)
        }}
      />
      <ConfirmDialog
        modalOpen={modalDelete}
        title={`Delete Deposit`}
        body={`Are you sure to delete Transcation ID(${editData?.id})?`}
        onToggle={() => setModalDelete(false)}
        onConfirm={() => {
          setModalDelete(false)
          deleteDeposit(editData.id)
          setTimeout(() => {
            fetchDeposit(url)
          }, 1000)
        }}
      />
    </>
  )
}

export default Deposit
