import React from 'react'
import {
  getAll,
  each,
  store,
  deleteData,
  update,
} from 'services/paymentMethodService'

const initialState = { paymentMethods: [], loading: false }

function reducer(state, action) {
  switch (action.type) {
    case 'SET_PLAN':
      return { ...state, paymentMethods: action.payload.data }
    case 'SET_LOADING':
      return { ...state, loading: action.payload }
    default:
      throw new Error()
  }
}

const usePaymentMethod = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  const fetchPaymentMethod = async () => {
    dispatch({ type: 'SET_LOADING', payload: true })
    let data = await getAll()
    dispatch({ type: 'SET_PLAN', payload: { data: data } })
    dispatch({ type: 'SET_LOADING', payload: false })
  }
  const getPaymentMethod = async (id) => {
    dispatch({ type: 'SET_LOADING', payload: true })
    let data = await each(Number(id))

    dispatch({ type: 'SET_PLAN', payload: { data: data } })
    dispatch({ type: 'SET_LOADING', payload: false })
  }

  const storePaymentMethod = async (values) => {
    await store(values)
  }
  const updatePaymentMethod = async (id, values) => {
    await update(id, values)
  }

  const deletePaymentMethod = async (id) => {
    await deleteData(id)
  }

  return {
    getPaymentMethod,
    fetchPaymentMethod,
    storePaymentMethod,
    updatePaymentMethod,
    deletePaymentMethod,
    state: state,
  }
}

export default usePaymentMethod
