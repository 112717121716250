import DateRangePicker from 'components/Inputs/DateRangePicker'
import TextBox from 'components/Inputs/TextBox'
import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { Row, Col, Button } from 'reactstrap'
import queryString from 'query-string'

function FilterBox() {
  const router = useHistory()

  const [fromMember, setFromMember] = useState(null)
  const [toMember, setToMember] = useState(null)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)

  const handleFilter = () => {
    const query = queryString.parse(router.location.search)
    query.page = 1

    if (fromMember) {
      query.fromMember = fromMember
    } else {
      delete query['fromMember']
    }

    if (toMember) {
      query.toMember = toMember
    } else {
      delete query['toMember']
    }

    if (
      startDate &&
      endDate &&
      startDate !== 'Invalid Date' &&
      endDate !== 'Invalid Date'
    ) {
      query.start_date = startDate
      query.end_date = endDate
    } else {
      delete query['start_date']
      delete query['end_date']
    }
    router.push(`${router.location.pathname}?${queryString.stringify(query)}`)
  }

  return (
    <Row>
      <Col md="2">
        <TextBox
          placeholder="From Member"
          onChange={(event) => setFromMember(event.target.value)}
        />
      </Col>
      <Col md="2">
        <TextBox
          placeholder="To Member"
          onChange={(event) => setToMember(event.target.value)}
        />
      </Col>
      <Col md="5">
        <DateRangePicker
          onDateChange={(value) => {
            setStartDate(value[0])
            setEndDate(value[1])
          }}
        />
      </Col>
      <Col md="3">
        <Button size="md" onClick={handleFilter}>
          Filter
        </Button>
        <Button size="md" onClick={() => window.location.reload()}>
          All
        </Button>
      </Col>
    </Row>
  )
}

export default FilterBox
