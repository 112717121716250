import React from 'react'
import List from './list'
import FilterBox from './filter'
import { useState } from 'react'
import DetailDialog from '../detail'
import useWithdraw from 'hooks/useWithdraw'
import EditDialog from '../edit/EditDialog'
import queryString from 'query-string'
import ConfirmDialog from 'components/Dialogs/ConfirmDialog'
import { useHistory } from 'react-router'

function Withdraw() {
  const router = useHistory()
  const [modalOpen, setModalOpen] = useState(false)
  const [editForm, setEditForm] = useState(false)
  const [modalCancel, setModalCancel] = useState(false)
  const [editData, setEditData] = React.useState(false)
  const [modalDelete, setModalDelete] = useState(false)

  const query = queryString.parse(router.location.search)
  let url = ''
  if (query.page) url += `?page=${query.page}`
  if (query.limit) url += `&limit=${query.limit}`
  if (query.start_date && query.end_date)
    url += `&start_date=${query.start_date}&end_date=${query.end_date}`
  if (query.search) url += `&search=${query.search}`

  const headers = [
    'No',
    'Transcation ID',
    'Name',
    'Code',
    'Amount',
    'Note',
    'Status',
    'Created At',
    '',
  ]

  const { state, fetchWithdraw, cancelWithdraw, deleteWithdraw } = useWithdraw()

  React.useEffect(() => {
    fetchWithdraw(url)
  }, [url])

  return (
    <>
      <List
        headers={headers}
        total={state.total}
        data={state.withdraws}
        totalAmount={state.totalAmount}
        title="Withdraw"
        onConfirm={(data) => {
          setEditData(data)
          setEditForm(true)
        }}
        onEdit={(data) => {
          setEditData(data)
          setModalOpen(true)
        }}
        onCancel={(data) => {
          setEditData(data)
          setModalCancel(true)
        }}
        onDelete={(data) => {
          setEditData(data)
          setModalDelete(true)
        }}
        loading={state.loading}
        filter={<FilterBox />}
        onSelect={(e) => {}}
        buttons={<></>}
      />
      <EditDialog
        modalOpen={editForm}
        data={editData}
        onToggle={() => {
          setEditForm(false)
          fetchWithdraw(url)
        }}
      />
      <DetailDialog
        modalOpen={modalOpen}
        data={editData}
        onToggle={() => {
          setModalOpen(false)
          fetchWithdraw(url)
        }}
      />
      <ConfirmDialog
        modalOpen={modalCancel}
        title={`Cancel Withdraw`}
        body={`Are you sure to cancel Transcation ID(${editData?.id})?`}
        onToggle={() => setModalCancel(false)}
        onConfirm={() => {
          setModalCancel(false)
          cancelWithdraw(editData.id)
          setTimeout(() => {
            fetchWithdraw(url)
          }, 1000)
        }}
      />
      <ConfirmDialog
        modalOpen={modalDelete}
        title={`Delete Withdraw`}
        body={`Are you sure to delete Transcation ID(${editData?.id})?`}
        onToggle={() => setModalDelete(false)}
        onConfirm={() => {
          setModalDelete(false)
          deleteWithdraw(editData.id)
          setTimeout(() => {
            fetchWithdraw(url)
          }, 1000)
        }}
      />
    </>
  )
}

export default Withdraw
