import React from 'react'
import EntryGrid from 'components/Inputs/EntryGrid'
import { Col, FormGroup, Row, Form } from 'reactstrap'
import Button from 'components/Button'
import TextBox from 'components/Inputs/TextBox'
import { useForm } from 'react-hook-form'
import usePlan from 'hooks/usePlan'

function InputForm({ title, data }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm()

  React.useEffect(() => {
    if (data) {
      setValue('name', data.name)
      setValue('price', data.price)
      setValue('maxLevel', data.maxLevel)
      setValue('totalClick', data.totalClick)
      setValue('activityIncome', data.activityIncome)
      setValue('noActivityIncome', data.noActivityIncome)
      setValue('bonus', data.bonus)
      setValue('duration', data.duration)
      setValue('referCount', data.referCount)
      setValue('color', data.color)
    }
  }, [data, setValue])

  const { storePlan, updatePlan } = usePlan()

  const submitData = async (values) => {
    let {
      name,
      price,
      maxLevel,
      activityIncome,
      noActivityIncome,
      bonus,
      referCount,
      duration,
      totalClick,
      color,
    } = values
    const formData = new FormData()
    formData.append('name', name)
    formData.append('price', price)
    formData.append('maxLevel', maxLevel)
    formData.append('activityIncome', activityIncome)
    formData.append('noActivityIncome', noActivityIncome)
    formData.append('bonus', bonus)
    formData.append('referCount', referCount)
    formData.append('duration', duration)
    formData.append('totalClick', totalClick)
    formData.append('color', color)

    if (values.logo.length > 0) {
      formData.append('logo', values.logo[0])
    }

    data ? await updatePlan(data.id, formData) : await storePlan(formData)
    reset()
  }

  return (
    <EntryGrid title={title}>
      <Form onSubmit={handleSubmit(submitData)}>
        <div className="pt-2 pb-2">
          <Row>
            <Col md="4">
              <FormGroup>
                <label>Name</label>
                <TextBox
                  className="form-control"
                  placeholder="Name"
                  type="text"
                  registerProps={register('name', {
                    required: 'Name is required',
                  })}
                  errors={errors.name}
                />
              </FormGroup>
            </Col>

            <Col md="4">
              <FormGroup>
                <label>Price</label>
                <TextBox
                  className="form-control"
                  placeholder="Price"
                  type="number"
                  registerProps={register('price', {
                    required: 'Price is required',
                  })}
                  errors={errors.price}
                />
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <label>Max Level</label>
                <TextBox
                  className="form-control"
                  placeholder="Max Level"
                  type="number"
                  registerProps={register('maxLevel', {
                    required: 'Max Level is required',
                  })}
                  errors={errors.maxLevel}
                  disabled={data ? true : false}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="4">
              <FormGroup>
                <label>Daily Activity Fee</label>
                <TextBox
                  className="form-control"
                  placeholder="DailyActivityFee"
                  registerProps={register('activityIncome', {
                    required: 'DailyActivityFee is required',
                  })}
                  errors={errors.activityIncome}
                  type="number"
                  step="any"
                />
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <label>Daily NoActivity Fee</label>
                <TextBox
                  className="form-control"
                  placeholder="DailyNoActivityFee"
                  registerProps={register('noActivityIncome', {
                    required: 'DailyNoActivityFee is required',
                  })}
                  errors={errors.noActivityIncome}
                  type="number"
                  step="any"
                />
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <label>Bonus</label>
                <TextBox
                  className="form-control"
                  placeholder="Bonus"
                  default={0}
                  registerProps={register('bonus')}
                  errors={errors.bonus}
                  type="number"
                  step="any"
                />
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <label>Duration</label>
                <TextBox
                  className="form-control"
                  placeholder="Duration"
                  default={0}
                  registerProps={register('duration')}
                  errors={errors.duration}
                  type="number"
                  step="any"
                />
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <label>Total Click</label>
                <TextBox
                  className="form-control"
                  placeholder="Total Click"
                  default={0}
                  registerProps={register('totalClick')}
                  errors={errors.totalClick}
                  type="number"
                />
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <label>Refer Count</label>
                <TextBox
                  className="form-control"
                  placeholder="Refer Count"
                  default={0}
                  registerProps={register('referCount')}
                  errors={errors.referCount}
                  type="number"
                />
              </FormGroup>
            </Col>
            <Col md="2">
              <FormGroup>
                <label>Color</label>
                <TextBox
                  className="form-control"
                  placeholder="Color"
                  registerProps={register('color')}
                  errors={errors.color}
                  type="color"
                />
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <label>Logo</label>
                <TextBox
                  className="form-control"
                  placeholder="Logo"
                  type="file"
                  registerProps={register('logo', {
                    required: data ? false : 'Logo is required',
                  })}
                  errors={errors.logo}
                  accept="image/*"
                />
              </FormGroup>
            </Col>
            {data && (
              <Col md="4">
                <img src={data.logo} alt="Image" width={100} height={100} />
              </Col>
            )}
          </Row>
          <div className="mt-5 text-center">
            <Button>Save</Button>
            <Button
              className="ml-2"
              onClick={() => {
                reset()
              }}
            >
              Reset
            </Button>
          </div>
        </div>
      </Form>
    </EntryGrid>
  )
}

export default InputForm
