import React from 'react'
import Table from 'components/Table'
import Button from 'components/Button'
import dayjs from 'dayjs'
import { percentageCalculator } from 'utli'
import { useHistory, useLocation } from 'react-router'
function List({
  headers,
  data,
  title,
  buttons,
  filter,
  loading,
  total,
  viewDetail,
}) {
  const router = useHistory()

  return (
    <Table
      title={title}
      headers={headers.map((e) => (
        <th scope="col" key={`thead-${Math.random()}`}>
          {e}
        </th>
      ))}
      loading={loading}
      filter={filter}
      buttons={buttons}
      total={total}
      rows={data?.map((e, index) => (
        <tr key={`table-row-${Math.random()}`}>
          <td>{index + 1}</td>
          <td>{e?.member.code}</td>
          <td>{e?.member.name}</td>
          <td>{e?.plan.name}</td>
          <td>{e?.mainPayment}</td>
          <td>{e?.childPayment}</td>
          <td>{e?.total}</td>
        </tr>
      ))}
    />
  )
}

export default List
