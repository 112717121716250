import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { NotificationContainer } from 'react-notifications'

import AdminLayout from 'layouts/Admin.js'
import AuthLayout from 'layouts/Auth.js'

import './app.scss'
function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
        <Route path="/" render={(props) => <AdminLayout {...props} />} />
      </Switch>
      <NotificationContainer />
    </BrowserRouter>
  )
}
export default App
