import React from 'react'
import EntryGrid from 'components/Inputs/EntryGrid'
import { Col, FormGroup, Row, Form } from 'reactstrap'
import Button from 'components/Button'
import TextBox from 'components/Inputs/TextBox'
import { useForm } from 'react-hook-form'
import useBlog from 'hooks/useBlog'

function InputForm({ title, data }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm()

  React.useEffect(() => {
    if (data) {
      setValue('title', data.title)
      setValue('body', data.body)
    }
  }, [data, setValue])

  const { storeBlog, updateBlog } = useBlog()

  const submitData = async (values) => {
    const formData = new FormData()
    formData.append('title', values.title)
    formData.append('body', values.body)

    if (values.image.length > 0) {
      formData.append('image', values.image[0])
    }
    data ? await updateBlog(data.id, formData) : await storeBlog(formData)
    reset()
  }

  return (
    <EntryGrid title={title}>
      <Form onSubmit={handleSubmit(submitData)} encType="multipart/form-data">
        <div className="pt-2 pb-2">
          <Row>
            <Col md="4">
              <FormGroup>
                <label>Title</label>
                <TextBox
                  className="form-control"
                  placeholder="Title"
                  registerProps={register('title', {
                    required: 'Title is required',
                  })}
                  errors={errors.title}
                />
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <label>Image</label>
                <TextBox
                  className="form-control"
                  placeholder="Image"
                  type="file"
                  registerProps={register('image', {
                    required: data ? false : 'Image is required',
                  })}
                  errors={errors.image}
                  accept="image/*"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="4">
              <FormGroup>
                <label>Body</label>
                <TextBox
                  className="form-control"
                  placeholder="Body"
                  type="textarea"
                  registerProps={register('body', {
                    required: 'Body is required',
                  })}
                  errors={errors.body}
                />
              </FormGroup>
            </Col>
            {data && (
              <Col md="4">
                <img src={data.image} alt="Image" width={100} height={100} />
              </Col>
            )}
          </Row>
          <div className="mt-5 text-center">
            <Button>Save</Button>
            <Button
              className="ml-2"
              onClick={() => {
                reset()
              }}
            >
              Reset
            </Button>
          </div>
        </div>
      </Form>
    </EntryGrid>
  )
}

export default InputForm
