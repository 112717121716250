import React from 'react'
import { useHistory } from 'react-router'
import Button from 'components/Button'
import List from './list'
import useAdmin from 'hooks/useAdmin'
import EditDialog from '../edit/EditDialog'
import { useState } from 'react'
import ConfirmDialog from 'components/Dialogs/ConfirmDialog'
import queryString from 'query-string'

function Admin() {
  const router = useHistory()
  const [editData, setEditData] = React.useState([])
  const [showEdit, setShowEdit] = useState(false)
  const [showDelete, setShowDelete] = React.useState(false)
  const query = queryString.parse(router.location.search)
  let url = ''
  if (query.page) url += `?page=${query.page}`
  if (query.limit) url += `&limit=${query.limit}`

  const headers = ['No', 'Name', 'Email', 'Role', 'Created At', '']

  const { state, fetchAdmins, deleteAdmins } = useAdmin()

  React.useEffect(() => {
    fetchAdmins(url)

    return () => fetchAdmins(url)
  }, [url])

  return (
    <>
      <List
        headers={headers}
        total={state.total}
        data={state.admins}
        title="Admins"
        onDelete={(data) => {
          setEditData(data)
          setShowDelete(true)
        }}
        loading={state.loading}
        onEdit={(data) => {
          setEditData(data)
          setShowEdit(true)
        }}
        onSelect={(e) => {}}
        buttons={
          <>
            <Button
              onClick={() => {
                router.push('admin-create')
              }}
            >
              Add New
            </Button>
            <Button
              onClick={() => {
                fetchAdmins(url)
              }}
            >
              Refresh
            </Button>
          </>
        }
      />
      <EditDialog
        modalOpen={showEdit}
        data={editData}
        onToggle={() => {
          setShowEdit(false)
          fetchAdmins(url)
        }}
      />
      <ConfirmDialog
        modalOpen={showDelete}
        title={`Delete Admin`}
        body={`Are you sure to delete ${editData?.name}?`}
        onToggle={() => setShowDelete(false)}
        onConfirm={() => {
          setShowDelete(false)
          deleteAdmins(editData.id)
          fetchAdmins(url)
        }}
      />
    </>
  )
}

export default Admin
