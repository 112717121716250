import React from 'react'
import { getAll, increase, decrease } from 'services/holdTokenHistoryService'

const initialState = {
  holdTokenHistories: [],
  loading: false,
  total: 0,
}

function reducer(state, action) {
  switch (action.type) {
    case 'SET_HOLD_TOKEN_HISTORY':
      return {
        ...state,
        holdTokenHistories: action.payload.data.histories,
        total: action.payload.data.total,
      }
    case 'SET_LOADING':
      return { ...state, loading: action.payload }
    default:
      throw new Error()
  }
}

const useHoldTokenHistory = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  const fetchHoldTokenHistory = async (id, query) => {
    dispatch({ type: 'SET_LOADING', payload: true })
    let data = await getAll(id, query)
    dispatch({ type: 'SET_HOLD_TOKEN_HISTORY', payload: { data: data } })
    dispatch({ type: 'SET_LOADING', payload: false })
  }

  const increaseHoldTokenHistory = async (id, data) => {
    await increase(id, data)
  }

  const decreaseHoldTokenHistory = async (id, data) => {
    await decrease(id, data)
  }

  return {
    fetchHoldTokenHistory,
    increaseHoldTokenHistory,
    decreaseHoldTokenHistory,
    state: state,
  }
}

export default useHoldTokenHistory
