import React from 'react'
import Table from 'components/Table'
import dayjs from 'dayjs'

function List({
  headers,
  data,
  onEdit,
  title,
  buttons,
  onDelete,
  selectedData = [],
  onSelect,
  filter,
  loading,
  total,
  totalAmount,
}) {
  return (
    <Table
      title={title}
      headers={headers.map((e) => (
        <th scope="col" key={`thead-${Math.random()}`}>
          {e}
        </th>
      ))}
      loading={loading}
      filter={filter}
      buttons={buttons}
      total={total}
      rows={data?.map((e, index) => (
        <tr key={`table-row-${Math.random()}`}>
          <td>{index + 1}</td>
          <td>{e.fromMember.name}</td>
          <td>{e.toMember.name}</td>
          <td>{e.amount}</td>
          <td>{e.transferFee}</td>
          <td>{e.total === 0 ? e.amount : e.total}</td>
          <td>{e.note}</td>
          <td>{dayjs(e.createdAt).format('DD/MM/YYYY')}</td>
        </tr>
      ))}
      totalAmount={totalAmount}
    />
  )
}

export default List
