import React from 'react'
import { each, store, update } from 'services/tokenPriceService'

const initialState = { tokenPrice: null, loading: false }

function reducer(state, action) {
  switch (action.type) {
    case 'SET_TOKEN_PRICE':
      return { ...state, tokenPrice: action.payload.data }
    case 'SET_LOADING':
      return { ...state, loading: action.payload }
    default:
      throw new Error()
  }
}

const useTokenPrice = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  const getTokenPrice = async () => {
    dispatch({ type: 'SET_LOADING', payload: true })
    let data = await each()

    dispatch({ type: 'SET_TOKEN_PRICE', payload: { data: data } })
    dispatch({ type: 'SET_LOADING', payload: false })
  }

  const storeTokenPrice = async (values) => {
    await store(values)
  }
  const updateTokenPrice = async (id, values) => {
    await update(id, values)
  }

  return {
    getTokenPrice,
    storeTokenPrice,
    updateTokenPrice,
    state: state,
  }
}

export default useTokenPrice
