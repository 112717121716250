import React, { useState } from 'react'
import List from './list'
import FilterBox from './filter'
import queryString from 'query-string'
import { useHistory } from 'react-router'
import useMember from 'hooks/useMember'
import IncreaseDialog from '../increase/IncreaseDialog'
import DecreaseDialog from '../decrease/DecreaseDialog'

function MyToken() {
  const router = useHistory()

  const [editData, setEditData] = useState(false)
  const [increase, setIncrease] = useState(false)
  const [decrease, setDecrease] = useState(false)

  const query = queryString.parse(router.location.search)
  let url = ''
  if (query.page) url += `?page=${query.page}`
  if (query.limit) url += `&limit=${query.limit}`
  if (query.start_date && query.end_date)
    url += `&start_date=${query.start_date}&end_date=${query.end_date}`
  if (query.search) url += `&search=${query.search}`

  const headers = ['No', 'Code', 'Name', 'MyToken', '']

  const { state, fetchMember } = useMember()

  React.useEffect(() => {
    fetchMember(url)
  }, [url])

  return (
    <>
      <List
        headers={headers}
        total={state.total}
        data={state.members}
        title="MyToken"
        onIncrease={(data) => {
          setEditData(data)
          setIncrease(true)
        }}
        onDecrease={(data) => {
          setEditData(data)
          setDecrease(true)
        }}
        loading={state.loading}
        filter={<FilterBox />}
        onSelect={(e) => {}}
        buttons={<></>}
      />
      <IncreaseDialog
        modalOpen={increase}
        data={editData}
        onToggle={() => {
          setIncrease(false)
          fetchMember(url)
        }}
      />
      <DecreaseDialog
        modalOpen={decrease}
        data={editData}
        onToggle={() => {
          setDecrease(false)
          fetchMember(url)
        }}
      />
    </>
  )
}

export default MyToken
