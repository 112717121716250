import { sendData, getData } from './apiService'
import { NotificationManager } from 'react-notifications'

export async function getAll(planId) {
  try {
    let response = await getData(`plan-setting/${planId}`)
    return response.data
  } catch (e) {
    NotificationManager.error('Opps. Something wrong')
    return false
  }
}

export async function store(planId, data) {
  try {
    let response = await sendData(`plan-setting/store/${planId}`, data)
    return response.data
  } catch (e) {
    NotificationManager.error('Opps. Something wrong')
    return false
  }
}
