import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { Row, Col, Button } from 'reactstrap'
import queryString from 'query-string'
import DatePicker from 'components/Inputs/DatePicker'

function FilterBox() {
  const router = useHistory()
  const [date, setDate] = useState(null)

  const handleFilter = () => {
    const query = queryString.parse(router.location.search)
    query.page = 1

    if (date && date !== 'Invalid Date') {
      query.date = date
    } else {
      delete query['date']
    }

    router.push(`${router.location.pathname}?${queryString.stringify(query)}`)
  }

  return (
    <Row>
      <Col md="4">
        <DatePicker onChange={(value) => setDate(value)} />
      </Col>
      <Col md="4">
        <Button size="md" onClick={handleFilter}>
          Search
        </Button>
      </Col>
    </Row>
  )
}

export default FilterBox
