import React from 'react'
import { getAll, each, store, deleteData, update } from 'services/blogService'

const initialState = { blogs: [], loading: false, total: 0 }

function reducer(state, action) {
  switch (action.type) {
    case 'SET_BLOG':
      return {
        ...state,
        blogs: action.payload.data.blogs,
        total: action.payload.data.total,
      }
    case 'SET_LOADING':
      return { ...state, loading: action.payload }
    default:
      throw new Error()
  }
}

const useBlog = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  const fetchBlog = async () => {
    dispatch({ type: 'SET_LOADING', payload: true })
    let data = await getAll()
    dispatch({ type: 'SET_BLOG', payload: { data: data } })
    dispatch({ type: 'SET_LOADING', payload: false })
  }
  const getBlog = async (id) => {
    dispatch({ type: 'SET_LOADING', payload: true })
    let data = await each(Number(id))

    dispatch({ type: 'SET_PLAN', payload: { data: data } })
    dispatch({ type: 'SET_LOADING', payload: false })
  }

  const storeBlog = async (values) => {
    await store(values)
  }
  const updateBlog = async (id, values) => {
    await update(id, values)
  }

  const deleteBlog = async (id) => {
    await deleteData(id)
  }

  return {
    getBlog,
    fetchBlog,
    storeBlog,
    updateBlog,
    deleteBlog,
    state: state,
  }
}

export default useBlog
