import React from 'react'
import { Card, CardBody } from 'reactstrap'
import Tree, { useTreeState } from 'react-hyper-tree'

function MemberTreeView({ data }) {
  const { required, handlers } = useTreeState({
    data,
    id: 'memberTree',
  })

  console.log(handlers)
  return (
    <Card>
      <CardBody>
        <h2>Tree</h2>
        <Tree {...required} {...handlers} />
      </CardBody>
    </Card>
  )
}

export default MemberTreeView
