import TextBox from 'components/Inputs/TextBox'
import React from 'react'
import { Row, Col } from 'reactstrap'
function FilterBox() {
  return (
    <Row>
      <Col md="6">
        <TextBox placeholder="Search here" />
      </Col>
    </Row>
  )
}

export default FilterBox
