import React from 'react'
import {
  getAll,
  each,
  store,
  deleteData,
  update,
} from 'services/withdrawAllowanceService'

const initialState = { withdrawAllowances: [], loading: false }

function reducer(state, action) {
  switch (action.type) {
    case 'SET_WITHDRAW_ALLOWANCE':
      return { ...state, withdrawAllowances: action.payload.data }
    case 'SET_LOADING':
      return { ...state, loading: action.payload }
    default:
      throw new Error()
  }
}

const useWithdrawAllowance = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  const fetchWithdrawAllowance = async () => {
    dispatch({ type: 'SET_LOADING', payload: true })
    let data = await getAll()
    dispatch({ type: 'SET_WITHDRAW_ALLOWANCE', payload: { data: data } })
    dispatch({ type: 'SET_LOADING', payload: false })
  }
  const getWithdrawAllowance = async (id) => {
    dispatch({ type: 'SET_LOADING', payload: true })
    let data = await each(Number(id))

    dispatch({ type: 'SET_WITHDRAW_ALLOWANCE', payload: { data: data } })
    dispatch({ type: 'SET_LOADING', payload: false })
  }

  const storeWithdrawAllowance = async (values) => {
    await store(values)
  }
  const updateWithdrawAllowance = async (id, values) => {
    await update(id, values)
  }

  const deleteWithdrawAllowance = async (id) => {
    await deleteData(id)
  }

  return {
    getWithdrawAllowance,
    fetchWithdrawAllowance,
    storeWithdrawAllowance,
    updateWithdrawAllowance,
    deleteWithdrawAllowance,
    state: state,
  }
}

export default useWithdrawAllowance
