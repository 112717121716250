import React from 'react'
import {
  store,
  getAll,
  update,
  deleteData,
  getEachMember,
  resetPassword,
} from './../services/memberService'

const initialState = { members: [], loading: false, member: null, total: 0 }

function reducer(state, action) {
  switch (action.type) {
    case 'SET_MEMBER':
      return {
        ...state,
        members: action.payload.data.members,
        total: action.payload.data.total,
      }
    case 'SET_LOADING':
      return { ...state, loading: action.payload }
    case 'SET_EACH_MEMBER':
      return { ...state, member: action.payload.data }

    default:
      throw new Error()
  }
}

const useMember = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  const fetchMember = async (query) => {
    dispatch({ type: 'SET_LOADING', payload: true })
    let data = await getAll(query)
    dispatch({ type: 'SET_MEMBER', payload: { data: data } })
    dispatch({ type: 'SET_LOADING', payload: false })
  }

  const storeMember = async (values) => {
    await store(values)
  }
  const updateMember = async (id, values) => {
    await update(id, values)
  }
  const deleteMember = async (id) => {
    await deleteData(id)
  }

  const fetchEachMember = async (id) => {
    dispatch({ type: 'SET_LOADING', payload: true })
    let data = await getEachMember(id)
    dispatch({ type: 'SET_EACH_MEMBER', payload: { data: data } })
    dispatch({ type: 'SET_LOADING', payload: false })
  }

  const resetPasswordMember = async (id) => {
    await resetPassword(id)
  }

  return {
    fetchMember,
    storeMember,
    updateMember,
    deleteMember,
    fetchEachMember,
    resetPasswordMember,
    state: state,
  }
}

export default useMember
