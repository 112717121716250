import React from 'react'
import Table from 'components/Table'
import dayjs from 'dayjs'
import { dateDiff } from 'utli/dateService'

function List({ headers, data, title, buttons, filter, loading, total }) {
  return (
    <Table
      title={title}
      headers={headers.map((e) => (
        <th scope="col" key={`thead-${Math.random()}`}>
          {e}
        </th>
      ))}
      loading={loading}
      filter={filter}
      buttons={buttons}
      total={total}
      rows={data?.map((e, index) => (
        <tr key={`table-row-${Math.random()}`}>
          <td>{index + 1}</td>
          <td>{e.member.name}</td>
          <td>{e.member.code}</td>
          <td>{e.plan.name}</td>
          <td>{dayjs(e.createdAt).format('DD/MM/YYYY')}</td>
          <td>
            {dayjs(
              new Date(e.createdAt).setDate(
                new Date(e.createdAt).getDate() + e.plan.duration
              )
            ).format('DD/MM/YYYY')}
          </td>
          <td>
            {dateDiff(
              new Date(),
              new Date(e.createdAt).setDate(
                new Date(e.createdAt).getDate() + e.plan.duration
              )
            )}
          </td>
        </tr>
      ))}
    />
  )
}

export default List
