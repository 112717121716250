import { getData, sendData } from './apiService'
import { NotificationManager } from 'react-notifications'

export async function getAll(query) {
  try {
    let response = await getData(`deposit-admin${query}`)
    return response.data
  } catch (e) {
    NotificationManager.error('Opps. Something wrong')
    return false
  }
}

export async function confirm(id) {
  try {
    await getData(`deposit-admin/confirm/${id}`)
    return true
  } catch (e) {
    NotificationManager.error('Opps. Something wrong')
    return false
  }
}

export async function deleteData(_id) {
  try {
    await sendData(`deposit-admin/${_id}`, null, 'DELETE')
    return true
  } catch (e) {
    NotificationManager.error('Opps. Something wrong')
    return false
  }
}