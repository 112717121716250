import React from 'react'
import { useHistory } from 'react-router'
import Button from 'components/Button'
import List from './list'
import FilterBox from './filter'
import EditDialog from '../edit/EditDialog'
import { useState } from 'react'
import ConfirmDialog from 'components/Dialogs/ConfirmDialog'
import usePaymentMethod from 'hooks/usePaymentMethod'

function PaymentMethod() {
  const router = useHistory()
  const [editData, setEditData] = React.useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [showDelete, setShowDelete] = React.useState(false)
  const [mounted, setMounted] = useState(false)
  const headers = ['No', 'BinanceID', 'Address', 'QRCode', 'Created_at', '', '']

  const { state, fetchPaymentMethod, deletePaymentMethod } = usePaymentMethod()

  React.useEffect(() => {
    if (!mounted) {
      setMounted(true)
      fetchPaymentMethod()
    }
  }, [mounted, fetchPaymentMethod])

  return (
    <>
      <List
        headers={headers}
        data={state.paymentMethods}
        title="Payment Method"
        onDelete={(data) => {
          setEditData(data)
          setShowDelete(true)
        }}
        loading={state.loading}
        onEdit={(data) => {
          setEditData(data)
          setModalOpen(true)
        }}
        filter={<FilterBox />}
        onSelect={(e) => {}}
        buttons={
          <>
            <Button
              onClick={() => {
                router.push('Payment-method-create')
              }}
            >
              Add New
            </Button>
            <Button
              onClick={() => {
                fetchPaymentMethod()
              }}
            >
              Refresh
            </Button>
          </>
        }
      />
      <EditDialog
        modalOpen={modalOpen}
        data={editData}
        onToggle={() => {
          setModalOpen(false)
          fetchPaymentMethod()
        }}
      />
      <ConfirmDialog
        modalOpen={showDelete}
        title={`Delete Payment Method`}
        body={`Are you sure to delete ${editData?.phone}?`}
        onToggle={() => setShowDelete(false)}
        onConfirm={() => {
          setShowDelete(false)
          deletePaymentMethod(editData.id)
          setMounted(false)
        }}
      />
    </>
  )
}

export default PaymentMethod
