import React from 'react'
import EntryGrid from 'components/Inputs/EntryGrid'
import { Col, Row, Form } from 'reactstrap'
import Button from 'components/Button'
import TextBox from 'components/Inputs/TextBox'
import { useForm } from 'react-hook-form'
import useTokenPrice from 'hooks/useTokenPrice'

function TokenPrice() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm()

  const {
    state,
    getTokenPrice,
    storeTokenPrice,
    updateTokenPrice,
  } = useTokenPrice()

  React.useEffect(() => {
    setValue('price', state.tokenPrice?.price)
  }, [setValue, state.tokenPrice])

  React.useEffect(() => {
    getTokenPrice()
  }, [])

  const submitData = async (values) => {
    if (state.tokenPrice === null) {
      await storeTokenPrice(values)
    } else {
      await updateTokenPrice(state.tokenPrice.id, values)
    }
  }

  return (
    <EntryGrid title="Token Price">
      <Form onSubmit={handleSubmit(submitData)}>
        <div className="pt-2 pb-2">
          <Row className="d-flex justify-content-center mb-4">
            <Col md="8">
              <div className="d-flex">
                <label className="col-5">1 USDT = Token</label>
                <TextBox
                  className="form-control"
                  placeholder="1 USD = Token"
                  type="number"
                  registerProps={register('price')}
                  errors={errors.price}
                />
              </div>
            </Col>
          </Row>
          <div className="mt-5 text-center">
            <Button type="submit">Save</Button>
          </div>
        </div>
      </Form>
    </EntryGrid>
  )
}

export default TokenPrice
