import React from 'react'
import { getAll, getChildAll } from 'services/paymentReportService'

const initialState = { paymentReports: [], loading: false, childLogs: [] }

function reducer(state, action) {
  switch (action.type) {
    case 'SET_PAYMENT_REPORT':
      return {
        ...state,
        paymentReports: action.payload.data,
      }
    // case 'SET_ADS_CHILD_FINISH':
    //   return {
    //     ...state,
    //     childLogs: action.payload.data,
    //   }
    case 'SET_LOADING':
      return { ...state, loading: action.payload }
    default:
      throw new Error()
  }
}

const useAdsFinish = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  const fetchPaymentReports = async (query) => {
    dispatch({ type: 'SET_LOADING', payload: true })
    let data = await getAll(query)
    dispatch({ type: 'SET_PAYMENT_REPORT', payload: { data: data } })
    dispatch({ type: 'SET_LOADING', payload: false })
  }
  //   const fetchAdsFinishDetailLogs = async (code, date) => {
  //     dispatch({ type: 'SET_LOADING', payload: true })
  //     let data = await getChildAll(code, date)
  //     dispatch({ type: 'SET_ADS_CHILD_FINISH', payload: { data: data } })
  //     dispatch({ type: 'SET_LOADING', payload: false })
  //   }

  return {
    fetchPaymentReports,
    // fetchAdsFinishDetailLogs,
    state: state,
  }
}

export default useAdsFinish
