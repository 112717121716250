import EntryGrid from 'components/Inputs/EntryGrid'
import dayjs from 'dayjs'
import React from 'react'
import { Modal, ModalBody, Row, Card, CardBody, Col } from 'reactstrap'

function DetailDialog({ data, modalOpen, onToggle }) {
  return (
    <Modal
      isOpen={modalOpen}
      size="lg"
      backdrop={true}
      style={{ top: '5%', maxWidth: '50%' }}
    >
      <div className="pt-3 pb-3 pr-2" style={{ background: '#e9e9ef' }}>
        <button
          aria-label="Close"
          className=" close"
          type="button"
          onClick={() => onToggle(!modalOpen)}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>

      <ModalBody className="waybill-detail-dialog">
        <Row className="d-flex justify-content-center">
          <Card style={{ width: '100%' }}>
            <CardBody>
              <EntryGrid title="Deposit Detail">
                <div className="pt-2 pb-2">
                  <Row className="mb-3">
                    <Col>Transcation ID : </Col>
                    <Col>{data.id}</Col>
                  </Row>
                  <Row className="mb-3">
                    <Col>Member Name : </Col>
                    <Col>{data.member?.name}</Col>
                  </Row>
                  <Row className="mb-3">
                    <Col>Amount : </Col>
                    <Col>{data.amount}</Col>
                  </Row>
                  <Row className="mb-3">
                    <Col>Note : </Col>
                    <Col>{data.note}</Col>
                  </Row>
                  <Row className="mb-3">
                    <Col>Image : </Col>
                    <Col>
                      <img
                        src={data.image}
                        alt="Receipt"
                        width={100}
                        height={100}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col>Status : </Col>
                    <Col>
                      <span
                        className={
                          data.status ? 'text-success' : 'text-primary'
                        }
                      >
                        {data.status ? 'Success' : 'Pending'}
                      </span>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col>Created At : </Col>
                    <Col>{dayjs(data.created_at).format('DD/MM/YYYY')}</Col>
                  </Row>
                </div>
              </EntryGrid>
            </CardBody>
          </Card>
        </Row>
      </ModalBody>
    </Modal>
  )
}
export default DetailDialog
