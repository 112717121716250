import DateRangePicker from 'components/Inputs/DateRangePicker'
import TextBox from 'components/Inputs/TextBox'
import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { Row, Col, Button } from 'reactstrap'
import queryString from 'query-string'
import DatePicker from 'components/Inputs/DatePicker'

function FilterBox() {
  const router = useHistory()

  const [search, setSearch] = useState(null)
  const [date, setDate] = useState(null)

  const handleFilter = () => {
    const query = queryString.parse(router.location.search)
    query.page = 1

    if (search) {
      query.code = search
    } else {
      delete query['search']
    }

    if (date) {
      query.date = date
    }

    router.push(`${router.location.pathname}?${queryString.stringify(query)}`)
  }

  return (
    <Row>
      <Col md="4">
        <TextBox
          placeholder="Search here"
          onChange={(event) => setSearch(event.target.value)}
        />
      </Col>
      <Col md="4">
        <DatePicker onChange={(value) => setDate(value)} />
      </Col>
      <Col md="4">
        <Button size="md" onClick={handleFilter}>
          Search
        </Button>
      </Col>
    </Row>
  )
}

export default FilterBox
