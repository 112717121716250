import { getData, sendData } from './apiService'
import { NotificationManager } from 'react-notifications'

export async function getAll(id, query) {
  try {
    let response = await getData(`history/${id}${query}`)
    return response.data
  } catch (e) {
    NotificationManager.error('Opps. Something wrong')
    return false
  }
}

export async function getWalletReport(query) {
  try {
    let response = await getData(
      `history/wallet-report?${new URLSearchParams(query)}`
    )
    return response.data
  } catch (e) {
    NotificationManager.error('Opps. Something wrong')
    return false
  }
}

export async function increase(id, data) {
  try {
    let response = await sendData(`history/increase/${id}`, data)
    return response.data
  } catch (e) {
    NotificationManager.error('Opps. Something wrong')
    return false
  }
}

export async function decrease(id, data) {
  try {
    let response = await sendData(`history/decrease/${id}`, data)
    return response.data
  } catch (e) {
    NotificationManager.error('Opps. Something wrong')
    return false
  }
}
