import React, { useEffect } from 'react'
import List from './list'
import FilterBox from './filter'
import queryString from 'query-string'
import { useHistory } from 'react-router'
import useWalletHistory from 'hooks/useHistory'

function WalletReport() {
  const router = useHistory()

  const headers = ['No', 'Code', 'Name', 'Amount']

  const query = queryString.parse(router.location.search)

  const { state, fetchWalletReport } = useWalletHistory()

  useEffect(() => {
    if (query && query.date) {
      fetchWalletReport(query)
    }
  }, [router.location])

  return (
    <>
      <List
        headers={headers}
        total={state.walletReportTotal}
        data={state.walletReports}
        title="Wallet Report"
        loading={state.loading}
        filter={<FilterBox />}
        onSelect={(e) => { }}
        buttons={<></>}
      />
    </>
  )
}

export default WalletReport
