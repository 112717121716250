import React from 'react'
import List from './list'
import FilterBox from './filter'
import queryString from 'query-string'
import { useHistory, useParams } from 'react-router'
import useWalletHistory from 'hooks/useHistory'

function History() {
  const { id } = useParams()
  const router = useHistory()

  const query = queryString.parse(router.location.search)
  let url = ''
  if (query.page) url += `?page=${query.page}`
  if (query.limit) url += `&limit=${query.limit}`

  const headers = ['No', 'Amount', 'Status', 'Date']

  const { state, fetchHistory } = useWalletHistory()

  React.useEffect(() => {
    fetchHistory(id, url)
  }, [url, id])

  return (
    <>
      <List
        headers={headers}
        total={state.total}
        data={state.histories}
        title="History"
        loading={state.loading}
        filter={<FilterBox />}
        onSelect={(e) => {}}
        buttons={<></>}
      />
    </>
  )
}

export default History
