import React from 'react'
import Button from 'components/Button'
import Table from 'components/Table'
import dayjs from 'dayjs'

function List({
  headers,
  data,
  onEdit,
  title,
  buttons,
  onConfirm,
  onDelete,
  selectedData = [],
  onSelect,
  filter,
  loading,
  total,
  totalAmount,
}) {
  return (
    <Table
      title={title}
      headers={headers.map((e) => (
        <th scope="col" key={`thead-${Math.random()}`}>
          {e}
        </th>
      ))}
      loading={loading}
      filter={filter}
      buttons={buttons}
      total={total}
      rows={data?.map((e, index) => (
        <tr key={`table-row-${Math.random()}`}>
          <td>{index + 1}</td>
          <td>{e.id}</td>
          <td>{e.member.name}</td>
          <td>{e.member.code}</td>
          <td>{e.amount}</td>
          <td>{e.note}</td>
          <td>
            <img src={e.image} alt="Receipt" width={100} height={100} />
          </td>
          <td>
            <span className={e.status ? 'text-success' : 'text-primary'}>
              {e.status ? 'Success' : 'Pending'}
            </span>
          </td>
          <td>{dayjs(e.createdAt).format('DD/MM/YYYY HH:mm:ss')}</td>
          <td>
            <Button size="sm" color="primary" onClick={() => onEdit(e)}>
              Detail
            </Button>
            {!e.status && (
              <Button size="sm" color="success" onClick={() => onConfirm(e)}>
                Confirm
              </Button>
            )}
            {!e.status && (
              <Button size="sm" color="danger" onClick={() => onDelete(e)}>
                Cancel
              </Button>
            )}
          </td>
        </tr>
      ))}
      totalAmount={totalAmount}
    />
  )
}

export default List
