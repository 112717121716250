import React from 'react'
import { useHistory } from 'react-router'
import Button from 'components/Button'
import List from './list'
import FilterBox from './filter'
import EditDialog from '../edit/EditDialog'
import { useState } from 'react'
import ConfirmDialog from 'components/Dialogs/ConfirmDialog'
import usePlan from 'hooks/usePlan'

function Plan() {
  const router = useHistory()
  const [editData, setEditData] = React.useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [showDelete, setShowDelete] = React.useState(false)
  const [mounted, setMounted] = useState(false)
  const headers = [
    'No',
    'Name',
    'Price',
    'MaxLeveL',
    'Percentage',
    'Bonus',
    'Duration',
    'Created_at',
    '',
    '',
  ]

  const { state, fetchPlan, softDeletePlan } = usePlan()

  React.useEffect(() => {
    if (!mounted) {
      setMounted(true)
      fetchPlan()
    }
  }, [mounted, fetchPlan])

  return (
    <>
      <List
        headers={headers}
        data={state.plans}
        title="Plan"
        onDelete={(data) => {
          setEditData(data)
          setShowDelete(true)
        }}
        loading={state.loading}
        onEdit={(data) => {
          setEditData(data)
          setModalOpen(true)
        }}
        filter={<FilterBox />}
        onSelect={(e) => { }}
        buttons={
          <>
            <Button
              onClick={() => {
                router.push('Plan-create')
              }}
            >
              Add New
            </Button>
            <Button
              onClick={() => {
                fetchPlan()
              }}
            >
              Refresh
            </Button>
          </>
        }
      />
      <EditDialog
        modalOpen={modalOpen}
        data={editData}
        onToggle={() => {
          setModalOpen(false)
          fetchPlan()
        }}
      />
      <ConfirmDialog
        modalOpen={showDelete}
        title={`Delete Plan`}
        body={`Are you sure to delete ${editData?.name}?`}
        onToggle={() => setShowDelete(false)}
        onConfirm={() => {
          setShowDelete(false)
          softDeletePlan(editData.id)
          fetchPlan()
        }}
      />
    </>
  )
}

export default Plan
