import React from 'react'
import { getAll, confirm, cancel, deleteData } from 'services/tokenWithdrawService'

const initialState = { tokenWithdraw: [], loading: false, total: 0, totalAmount: 0 }

function reducer(state, action) {
  switch (action.type) {
    case 'SET_TOKEN_WITHDRAWS':
      return {
        ...state,
        tokenWithdraw: action.payload.data.tokenWithdraws,
        total: action.payload.data.total,
        totalAmount: action.payload.data.totalAmount,
      }
    case 'SET_LOADING':
      return { ...state, loading: action.payload }
    default:
      throw new Error()
  }
}

const useTokenWithdraw = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  const fetchWithdraw = async (query) => {
    dispatch({ type: 'SET_LOADING', payload: true })
    let data = await getAll(query);
    dispatch({ type: 'SET_TOKEN_WITHDRAWS', payload: { data: data } })
    dispatch({ type: 'SET_LOADING', payload: false })
  }

  const confirmWithdraw = async (id, data) => {
    await confirm(id, data)
  }

  const cancelWithdraw = async (id) => {
    await cancel(id)
  }

  const deleteWithdraw = async (id) => {
    await deleteData(id)
  }

  return {
    fetchWithdraw,
    confirmWithdraw,
    cancelWithdraw,
    deleteWithdraw,
    state: state,
  }
}

export default useTokenWithdraw
