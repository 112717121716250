import React from 'react'
import { getAll } from 'services/transferService'

const initialState = { transfers: [], loading: false, total: 0, totalAmount: 0 }

function reducer(state, action) {
  switch (action.type) {
    case 'SET_TRANSFERS':
      return {
        ...state,
        transfers: action.payload.data.transfers,
        total: action.payload.data.total,
        totalAmount: action.payload.data.totalAmount,
      }
    case 'SET_LOADING':
      return { ...state, loading: action.payload }
    default:
      throw new Error()
  }
}

const useTransfer = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  const fetchTransfer = async (query) => {
    dispatch({ type: 'SET_LOADING', payload: true })
    let data = await getAll(query)
    dispatch({ type: 'SET_TRANSFERS', payload: { data: data } })
    dispatch({ type: 'SET_LOADING', payload: false })
  }

  return {
    fetchTransfer,
    state: state,
  }
}

export default useTransfer
