import React, { useState } from 'react'
import { CardBody, Card } from 'reactstrap'
import { useParams } from 'react-router'
import usePlan from 'hooks/usePlan'
import AddFee from './addFee'

function PlanSetting() {
  let { id } = useParams()
  const [mounted, setMounted] = useState(false)

  const { state, getPlan } = usePlan()

  React.useEffect(() => {
    if (!mounted) {
      setMounted(true)
      getPlan(id)
    }
  }, [getPlan, id, mounted])
  const data = state?.plans

  return (
    <div>
      <Card className="py-2">
        <CardBody>
          <h2>{`Plan Setting - ${data?.name} (${data?.price})`}</h2>
        </CardBody>
        <CardBody></CardBody>
      </Card>
      <AddFee level={data?.maxLevel} />
    </div>
  )
}

export default PlanSetting
