import { sendData, getData } from './apiService'
import { NotificationManager } from 'react-notifications'

export async function each() {
  try {
    let response = await getData(`token-prices`)
    return response.data
  } catch (e) {
    NotificationManager.error('Opps. Something wrong')
    return false
  }
}

export async function store(data) {
  try {
    let response = await sendData('token-prices/store', data)
    return response.data
  } catch (e) {
    NotificationManager.error('Opps. Something wrong')
    return false
  }
}
export async function update(id, data) {
  try {
    let response = await sendData(`token-prices/${id}`, data, 'PUT')
    return response.data
  } catch (e) {
    NotificationManager.error('Opps. Something wrong')
    return false
  }
}
