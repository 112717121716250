import React from 'react'
import {
  Modal,
  ModalBody,
  Row,
  Card,
  CardBody,
  Form,
  Col,
  FormGroup,
  Button,
} from 'reactstrap'
import { useForm } from 'react-hook-form'
import EntryGrid from 'components/Inputs/EntryGrid'
import TextBox from 'components/Inputs/TextBox'
import useWalletHistory from 'hooks/useHistory'
import { NotificationManager } from 'react-notifications'

function DecreaseDialog({ data, modalOpen, onToggle }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm()

  const { decreaseHistory } = useWalletHistory()

  const submitData = async (values) => {
    if (data.wallet < values.amount) {
      NotificationManager.warning('Amount is too much!')
    } else {
      await decreaseHistory(data.id, values)
      reset()
    }
  }

  return (
    <Modal
      isOpen={modalOpen}
      size="lg"
      backdrop={true}
      style={{ top: '5%', maxWidth: '50%' }}
    >
      <div className="pt-3 pb-3 pr-2" style={{ background: '#e9e9ef' }}>
        <button
          aria-label="Close"
          className=" close"
          type="button"
          onClick={() => onToggle(!modalOpen)}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>

      <ModalBody className="waybill-detail-dialog">
        <Row className="d-flex justify-content-center">
          <Card style={{ width: '100%' }}>
            <CardBody>
              <EntryGrid title="Decrease">
                <Form onSubmit={handleSubmit(submitData)}>
                  <div className="pt-2 pb-2">
                    <Row>
                      <Col>
                        <FormGroup>
                          <label>Amount</label>
                          <TextBox
                            className="form-control"
                            placeholder="Amount"
                            type="number"
                            registerProps={register('amount', {
                              required: 'Amount is required',
                            })}
                            step="any"
                            errors={errors.amount}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <div className="mt-5 text-center">
                      <Button>Save</Button>
                      <Button
                        className="ml-2"
                        onClick={() => {
                          reset()
                        }}
                      >
                        Reset
                      </Button>
                    </div>
                  </div>
                </Form>
              </EntryGrid>
            </CardBody>
          </Card>
        </Row>
      </ModalBody>
    </Modal>
  )
}
export default DecreaseDialog
