import useAdsFinish from 'hooks/useAdsFinish'
import React, { useEffect } from 'react'
import { useParams } from 'react-router'
import Table from 'components/Table'
import dayjs from 'dayjs'
import { percentageCalculator } from 'utli'

function AdsFinishDetail({ data }) {
  const headers = ['No', 'Code', 'Member', 'Plan', 'Payment', 'Total', 'Date']

  return (
    <div>
      <Table
        headers={headers.map((e) => (
          <th scope="col" key={`thead-${Math.random()}`}>
            {e}
          </th>
        ))}
        rows={data?.map((e, index) => (
          <tr key={`table-row-${Math.random()}`}>
            <td>{index + 1}</td>
            <td>{e.member.code}</td>
            <td>{e.member.name}</td>
            <td>{e.member.plan.name}</td>
            <td>
              {e.member.plan.activityIncome}% of {e.member.plan.price}
            </td>
            <td>
              {' '}
              {percentageCalculator(
                e.member.plan.price,
                e.member.plan.activityIncome
              )}
            </td>
            <td>{dayjs(e.date).format('DD/MM/YYYY')}</td>
          </tr>
        ))}
      />
    </div>
  )
}

export default AdsFinishDetail
