import React from 'react'
import { getAll, store } from 'services/planSettingService'

const initialState = { planSettings: [], loading: false }

function reducer(state, action) {
  switch (action.type) {
    case 'SET_PLAN':
      return { ...state, planSettings: action.payload.data }
    case 'SET_LOADING':
      return { ...state, loading: action.payload }
    default:
      throw new Error()
  }
}

const usePlanSetting = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  const fetchPlanSetting = async (planId) => {
    dispatch({ type: 'SET_LOADING', payload: true })
    let data = await getAll(planId)
    dispatch({ type: 'SET_PLAN', payload: { data: data } })
    dispatch({ type: 'SET_LOADING', payload: false })
  }

  const storePlanSetting = async (planId, values) => {
    await store(planId, values)
  }

  return {
    fetchPlanSetting,
    storePlanSetting,
    state: state,
  }
}

export default usePlanSetting
