import React, { useState } from 'react'
import { Row, Col, Button, Card, Container } from 'reactstrap'
import * as MemberService from './../../../services/memberService';
import TextBox from 'components/Inputs/TextBox'
function TranscationReport() {
    const [search, setSearch] = useState("");
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const handleFilter = async () => {
        if (search.length > 0) {
            setLoading(true);
            const response = await MemberService.getTranscationReport(search.trim());
            setData(response);
            setLoading(false);
        }
    }
    return (
        <Container>
            <Row>
                <Col md="3">
                    <TextBox
                        placeholder="Search here"
                        onChange={(event) => setSearch(event.target.value)}
                    />
                </Col>
                <Col md="3">
                    <Button size="md" onClick={handleFilter} disabled={loading}>
                        Search
                    </Button>
                </Col>
            </Row>

            {data && <Card className='mt-3 p-4'>
                <div className='d-flex'>
                    <h3 style={{ width: "200px" }}>Total Deposit : </h3>
                    <h3>{data?.depositTotal[0]?.total}</h3>
                </div>
                <div className='d-flex'>
                    <h3 style={{ width: "200px" }}>Total Withdraw : </h3>
                    <h3>{data?.withDrawTotal[0]?.total}</h3>
                </div>
                <div className='d-flex'>
                    <h3 style={{ width: "200px" }}>Total Increase : </h3>
                    <h3>{data?.increseTotal[0]?.total}</h3>
                </div>
                <div className='d-flex'>
                    <h3 style={{ width: "200px" }}>Total Decrease : </h3>
                    <h3>{data?.decreaseTotal[0]?.total}</h3>
                </div>
                <div className='d-flex'>
                    <h3 style={{ width: "200px" }}>Total Transfer In : </h3>
                    <h3>{data?.transferInTotal[0]?.total}</h3>
                </div>
                <div className='d-flex'>
                    <h3 style={{ width: "200px" }}>Total Transfer Out : </h3>
                    <h3>{data?.transferOutTotal[0]?.total}</h3>
                </div>
                <div className='d-flex'>
                    <h3 style={{ width: "200px" }}>Total Income : </h3>
                    <h3>{data?.incomeTotal[0]?.total}</h3>
                </div>
            </Card>}
        </Container >
    )
}

export default TranscationReport